import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { items, setResetItems } from "redux/slices/cartSlice";
import { assignLoggedInUser, loggedInUserStore } from "redux/slices/userSlice";
import { LocalRoutes } from "utils/LocalRoutes";
import { getLocalStorageData } from "utils/LocalStorage";
import usericon from "assets/template/img/icon/usericon.png";
import logo from "assets/template/img/logo/dwainlogo.png";
import { Autocomplete, TextField } from "@mui/material";
import { searchCategoryProducts } from "api/General";
import Stack from '@mui/material/Stack';

import { siteData } from "data";
import DropDownMenu from "components/util/DropDownMenu";
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
type SearchObject = {
    name: string;

}

const Header: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const cartitems = useAppSelector(items)
    const navigate = useNavigate()
    const loggedInUserStoreRef = useAppSelector(loggedInUserStore)
    const dispatch = useDispatch()
    const [mobilemenuactive, setmobilemenuactive] = useState(true)
    const [activesearch, setactivesearch] = useState(false)
    const [searchResults, setSearchResults] = useState<SearchObject[]>([]);

    const handleClick = () => {
        setOpen(!open);
    };
    const handleClick1 = () => {
        setOpen1(!open1);
    };
    const handleClick2 = () => {
        setOpen2(!open2);
    };
    

    const inputsearchtype = async (newinputvalue: string) => {
        let payload = { searchtxt: newinputvalue }
        const res: SearchObject[] = await searchCategoryProducts(payload);
        setSearchResults(res)
        //console.log(res)
        /*if (res?.success) {
            console.log("Calling the reducer")
            console.log(res.data)
            //setBanners(res.data)
            dispatch(setProducts({ products: res.data.products }));
        } else {
            console.log("show error here");
        }*/
    }

    useEffect(() => {
        let localloggedinuser = getLocalStorageData("loggedinuser")
        console.log(localloggedinuser)
        if (localloggedinuser) {

            console.log("Fetching user from local storage")

            dispatch(assignLoggedInUser({ user: { ...localloggedinuser }, updateLocalStore: false }))
            console.log("Length of user is more")

        }
        let localcart = getLocalStorageData("cart")
        if (localcart) {
            localcart = Object.values(localcart);
            if (localcart.length > 0)
                dispatch(setResetItems(localcart))
        }
        console.log("Fetching access token")
        let token = getLocalStorageData("accesstoken")
        console.log(token)

    }, [])

    


    return (
        <div>
            <div className={mobilemenuactive ? 'offcanvas-menu-overlay' : 'offcanvas-menu-overlay active'}></div>
            <div className={mobilemenuactive ? 'offcanvas-menu-wrapper' : 'offcanvas-menu-wrapper active'}>

                <div className="offcanvas__close" onClick={() => {
                    setmobilemenuactive(!mobilemenuactive)
                }}>+</div>
                <ul className="offcanvas__widget">
                    {/* <li><span className="icon_search search-switch" onClick={()=>{setactivesearch(!activesearch)}}></span></li> */}
                    {/* <li><a href="#"><span className="icon_heart_alt"></span>
                            <div className="tip">2</div>
                        </a></li> */}

                        <li><a href="#" onClick={()=>{
                                navigate(LocalRoutes.general.cart)
                            }}><span className="icon_bag_alt"></span>
                            <div className="tip">{ cartitems.length }</div>
                            </a>
                        </li>
                    </ul>
                    <div className="offcanvas__logo">
                        <img src={siteData.headerlogourl} />
                        <a href=""></a>
                    </div>
                    {loggedInUserStoreRef.loggedInUser.id && 
                        <span className="header__right__auth">
                          <img src={usericon} alt=""></img>
                            <a href="#" className="loggedinuser" onClick={()=>{
                                navigate(LocalRoutes.user.profile)
                                }}>Hello, { loggedInUserStoreRef.loggedInUser.name ?? 'User' }</a><br/>
                            
                        </span>
                    }
                    <div id="mobile-menu-wrap">
                        <div className="slicknav_nav ">
                            <ul>
                                <li>
                                    <a href="./">Home</a>
                                </li>
                                <li >
                                    <a href="#">Categories</a>
                                </li>
                                <li >
                                    <a href="#" >Brands</a>
                                </li>
                                <li >
                                    <a href="#" >Contact</a>
                                </li>
                                <li >
                                    <a href="#" onClick={()=>{
                                        //logout();
                                    }}>Logout</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <li><a href="#" onClick={() => {
                        navigate(LocalRoutes.general.cart)
                    }}><span className="icon_bag_alt"></span>
                        <div className="tip">{cartitems.length}</div>
                    </a>
                    </li> */}
                    
                <div className="offcanvas__logo">
                    <img src={logo} />
                    <a href=""></a>
                </div>
                {loggedInUserStoreRef.loggedInUser.id &&
                    <span className="header__right__auth">
                        <img src={usericon} alt=""></img>
                        <a href="#" className="loggedinuser" onClick={() => {
                            navigate(LocalRoutes.user.profile)
                        }}>Hello, {loggedInUserStoreRef.loggedInUser.firstname?? 'User'}</a><br />

                    </span>
                }
                <div id="mobile-menu-wrap">
                    <div >
                        <List
                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper',fontWeight:"700"}}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                           
                        >
                            <ListItemButton>
                                
                                <ListItemText primary="Home" 
                                onClick={
                                    () => {
                                        { setmobilemenuactive(!mobilemenuactive) }
                                        navigate(LocalRoutes.general.homePage)
                                    }
                                }
                                    primaryTypographyProps={{
                                        fontSize: 16,
                                        fontWeight: 'bold',
                                        letterSpacing: 0,
                                    }}
                                />
                            </ListItemButton>
                            <ListItemButton onClick={handleClick}>
                                
                                <ListItemText primary="Grocery" 
                                    primaryTypographyProps={{
                                        fontSize: 16,
                                        fontWeight: 'bold',
                                        letterSpacing: 0,
                                    }}/>
                                {open ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItemButton sx={{ pl: 4 }}>
                                       
                                        <ListItemText primary="Fresh Vegetables" onClick={
                                                () => {
                                                    navigate("/category/fresh-vegetables")
                                                }
                                            }/>
                                    </ListItemButton>
                                </List>
                                <List component="div" disablePadding>
                                    <ListItemButton sx={{ pl: 4 }}>
                                       
                                        <ListItemText primary="Fresh Fruits" onClick={
                                                () => {
                                                    navigate("/category/fresh-fruits")
                                                }
                                            }/>
                                    </ListItemButton>
                                </List>
                                <List component="div" disablePadding>
                                    <ListItemButton sx={{ pl: 4 }}>
                                       
                                        <ListItemText primary="Dairy & Bakery" onClick={
                                                () => {
                                                    navigate("/category/dairy-bakery")
                                                }
                                            }/>
                                    </ListItemButton>
                                </List>
                                <List component="div" disablePadding>
                                    <ListItemButton sx={{ pl: 4 }}>
                                       
                                        <ListItemText primary="Food Grains & Staples" onClick={
                                                () => {
                                                    navigate("/category/food-grains-staples")
                                                }
                                            }/>
                                    </ListItemButton>
                                </List>
                                <List component="div" disablePadding>
                                    <ListItemButton sx={{ pl: 4 }}>
                                       
                                        <ListItemText primary="Snacks & Packed Foods" onClick={
                                                () => {
                                                    navigate("/category/snacks-packed-foods")
                                                }
                                            }/>
                                    </ListItemButton>
                                </List>
                                <List component="div" disablePadding>
                                    <ListItemButton sx={{ pl: 4 }}>
                                       
                                        <ListItemText primary="Edible Oil & Spices" onClick={
                                                () => {
                                                    navigate("/category/edible-oil-and-spices")
                                                }
                                            }/>
                                    </ListItemButton>
                                </List>
                                <List component="div" disablePadding>
                                    <ListItemButton sx={{ pl: 4 }}>
                                       
                                        <ListItemText primary="All Food & Gourmet" onClick={
                                                () => {
                                                    navigate("/category/all-food-and-gourmet")
                                                }
                                            }/>
                                    </ListItemButton>
                                </List>
                                <List component="div" disablePadding>
                                    <ListItemButton sx={{ pl: 4 }}>
                                       
                                        <ListItemText primary="Dry Fruit & Other" onClick={
                                                () => {
                                                    navigate("/category/dry-fruit-other")
                                                }
                                            }/>
                                    </ListItemButton>
                                </List>
                                
                            </Collapse>

                            <ListItemButton onClick={handleClick1}>
                                
                                <ListItemText primary="Clothing" 
                                primaryTypographyProps={{
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    letterSpacing: 0,
                                }}/>
                                {open1 ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={open1} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItemButton sx={{ pl: 4 }}>
                                       
                                        <ListItemText primary="Mens" onClick={
                                                () => {
                                                    navigate("/category/mens")
                                                }
                                            }/>
                                    </ListItemButton>
                                </List>
                                <List component="div" disablePadding>
                                    <ListItemButton sx={{ pl: 4 }}>
                                       
                                        <ListItemText primary="Womens" onClick={
                                                () => {
                                                    navigate("/category/womens")
                                                }
                                            }/>
                                    </ListItemButton>
                                </List>
                                <List component="div" disablePadding>
                                    <ListItemButton sx={{ pl: 4 }}>
                                       
                                        <ListItemText primary="Kids" onClick={
                                                () => {
                                                    navigate("/category/kids")
                                                }
                                            }/>
                                    </ListItemButton>
                                </List>
                            </Collapse>
                            <ListItemButton onClick={handleClick2}>
                                
                                <ListItemText primary="Home Essentials" 
                                primaryTypographyProps={{
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    letterSpacing: 0,
                                }}/>
                                {open2 ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={open2} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItemButton sx={{ pl: 4 }}>
                                       
                                        <ListItemText primary="Home Decor" onClick={
                                                () => {
                                                    navigate("/category/home-decor")
                                                }
                                            }/>
                                    </ListItemButton>
                                </List>
                                <List component="div" disablePadding>
                                    <ListItemButton sx={{ pl: 4 }}>
                                       
                                        <ListItemText primary="Handmade" onClick={
                                                () => {
                                                    navigate("/category/handmade")
                                                }
                                            }/>
                                    </ListItemButton>
                                </List>
                                <List component="div" disablePadding>
                                    <ListItemButton sx={{ pl: 4 }}>
                                       
                                        <ListItemText primary="Curtains & Pillow" onClick={
                                                () => {
                                                    navigate("/category/curtains-pillows")
                                                }
                                            }/>
                                    </ListItemButton>
                                </List>
                                <List component="div" disablePadding>
                                    <ListItemButton sx={{ pl: 4 }}>
                                       
                                        <ListItemText primary="Furniture" onClick={
                                                () => {
                                                    navigate("/category/furniturs")
                                                }
                                            }/>
                                    </ListItemButton>
                                </List>
                            </Collapse>
                            <ListItemButton>
                                
                                <ListItemText primary="Contact" 
                                
                                    primaryTypographyProps={{
                                        fontSize: 16,
                                        fontWeight: 'bold',
                                        letterSpacing: 0,
                                    }}
                                />
                            </ListItemButton>
                        </List>

                    </div>
                </div>
            </div>
            <header className="header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-3 col-lg-2">
                            <div className="header__logo">

                            <img src={siteData.headerlogourl} />
                                <a href=""></a>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7">
                            <nav className="header__menu">

                                <DropDownMenu/>
                            </nav>
                        </div>
                        <div className="col-lg-3">
                            <div className="header__right">
                                {!loggedInUserStoreRef.loggedInUser.id &&
                                    <div className="header__right__auth">
                                        <span onClick={
                                            () => {
                                                navigate(LocalRoutes.general.login)
                                            }
                                        }>Login /</span>
                                        <span onClick={
                                            () => {
                                                navigate(LocalRoutes.general.login)
                                            }
                                        }> Register</span>

                                    </div>
                                }
                                {loggedInUserStoreRef.loggedInUser.id &&
                                    <span className="header__right__auth">
                                        <a href="#" className="loggedinuser">Hello, {loggedInUserStoreRef.loggedInUser.firstname ?? 'User'}</a><br />
                                        <a href="#" className="logoutbtn">Logout</a>
                                    </span>
                                }
                                <ul className="header__right__widget">
                                    <li>
                                        {/* <input type="text" /> */}
                                    </li>
                                    <li><span className="icon_search search-switch" onClick={() => { setactivesearch(!activesearch) }}></span></li>
                                    {/* <li><a href="#"><span className="icon_heart_alt"></span>
                                        <div className="tip">2</div>
                                    </a></li> */}
                                    <li><a href="#" onClick={() => {
                                        navigate(LocalRoutes.general.cart)
                                    }}><span className="icon_bag_alt"></span>
                                        <div className="tip">{cartitems.length}</div>
                                    </a>
                                    </li>
                                    {loggedInUserStoreRef.loggedInUser.id ?
                                        (<li>
                                            <a href="#" onClick={() => {
                                                navigate(LocalRoutes.user.profile)
                                            }}><img src={usericon} alt=""></img>
                                            </a><br />

                                        </li>) : (
                                            <li>
                                                <a href="#" onClick={() => {
                                                    navigate(LocalRoutes.general.login)
                                                }}><img src={usericon} alt=""></img>
                                                </a><br />

                                            </li>

                                        )

                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="canvas__open " style={{ right: "100px" }} onClick={() => {
                            navigate(LocalRoutes.general.cart)
                        }}><span className="icon_bag_alt"></span>
                            <div className="tip">{cartitems.length}</div>
                    </div>                
                   
                    <div className="canvas__open icon_search search-switch" style={{ right: "50px" }} onClick={() => { setactivesearch(!activesearch) }}></div>
                    <div className="canvas__open" >
                        <i className="fa fa-bars" onClick={() => { setmobilemenuactive(!mobilemenuactive) }}></i>
                    </div>

                </div>
            </header>
            {activesearch &&
                <div className="header2 col-md-12  flex-row content-center">
                    {/* <input type="text" className="search-txt" placeholder="Search ........."/> */}
                    <Stack spacing={2}>
                        <Autocomplete

                            id="size-small-standard"

                            sx={{ width: 300 }}
                            options={searchResults}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField {...params} variant="standard"
                                    label="Search "
                                    placeholder="Search......"


                                />
                            )}
                            onInputChange={(event, newinputvalue) => {
                                inputsearchtype(newinputvalue)
                            }}
                        /></Stack>
                </div>
            }
        </div>
    )
}

export default Header;