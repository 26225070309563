import { ImageType } from "components/products/ProductType";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks"
import { addProductToCart } from "redux/slices/cartSlice";
import { selectedProductSlice } from "redux/slices/productSlice"
import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

type ThumbImageType = {
    image:ImageType
}

const ProductDetailComponent: React.FC = () => {

    const selectedProduct = useAppSelector(selectedProductSlice);
    const [selectedimage,setSelectedImage] = useState<ImageType| null>()
    const [addcartsnack,setaddcartsnack] =useState(false);
    const [quantity,setQuantity]=useState(1)
    
    const dispatch = useAppDispatch()
    const addtocart = ()=>{
        dispatch(addProductToCart({qty:quantity,product:{...selectedProduct}}))
        setaddcartsnack(true);

    }
    const handleCloseSnack =() =>{
        setaddcartsnack(false);
    }
    useEffect(()=>{
        setSelectedImage(selectedProduct?.images?selectedProduct?.images[0]:null)
    },[selectedProduct?.images])
    
    const images = selectedProduct?.images?.filter(el=>{
        if(el.width==270 && el.height==360){
            return true
        }else{
            return false
        }
     })

    const ThumbImage = (props:ThumbImageType):ReactElement => {
        return (
            <a href='#' className="pt"
                key={props.image.id}
                onClick={() => {
                    setSelectedImage(props.image)
                }}
            >
                <img src={props.image.fullurl} alt=""/>
            </a>
        )
    }

    return (
        <section className="product-details spad">
        <div className="container">
            <div className="row">
            
                <div className="col-lg-6">
                    <div className="product__details__pic">
                        <div className="product__details__pic__left product__thumb nice-scroll">
                        {images?.map((image:ImageType)=>(
                                <ThumbImage
                                    key={image.id}
                                    image={image}
                                />
                                )
                            )
                        }
                        </div>
                        <div className="product__details__slider__content">
                            <div className="product__details__pic__slider owl-carousel">
                                <img data-hash="product-1" className="product__big__img" src={selectedimage?.fullurl} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="product__details__text">
                        <h3>{selectedProduct.name}<span>{selectedProduct?.title}</span></h3>
                        <div className="rating">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <span>( 138 reviews )</span>
                        </div>
                        <div className="product__details__price">{selectedProduct.sp}<span>{selectedProduct.mrp}</span></div>
                        <p><b>{selectedProduct.description}</b></p>
                        <div className="product__details__button">
                            <div className="quantity">
                                <span>Quantity:</span>
                                <div className="pro-qty">
                                    <input type="number" value={quantity} 
                                        onChange={(e)=>setQuantity(parseInt(e.target.value))}
                                    />
                                </div>
                            </div>
                            <div className="cart-btn"
                                onClick={()=>{
                                    addtocart();
                                }}
                            ><span className="icon_bag_alt"></span> Add to cart</div>
                            <Snackbar open={addcartsnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                                <Alert onClose={handleCloseSnack} variant="filled" severity="success"  sx={{ width: '100%' }}>
                                    Added to Cart
                                </Alert>
                            </Snackbar>
                            <ul>
                                <li><a href="#"><span className="icon_heart_alt"></span></a></li>
                                <li><a href="#"><span className="icon_adjust-horiz"></span></a></li>
                            </ul>
                        </div>
                      
                        <div   className="product__details__widget">
                           
                               <ul>
                                <li>
                                    <span>Availability:</span>
                                    <div className="stock__checkbox">
                                        <label>
                                            In Stock                                           
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <span>Available color:</span>
                                    <div className="color__checkbox">
                                        <label>
                                            
                                            <span className="checkmark"></span>
                                        </label>
                                        <label>
                                            
                                            <span className="checkmark black-bg"></span>
                                        </label>
                                        <label>                                            
                                            <span className="checkmark grey-bg"></span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <span>Available size:</span>
                                    <div className="size__btn">
                                        <label className="active">
                                            
                                            xs
                                        </label>
                                        <label>
                                            
                                            s
                                        </label>
                                        <label>
                                            
                                            m
                                        </label>
                                        <label>
                                            
                                            l
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <span>Promotions:</span>
                                    <p>Free shipping</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
        </div></div></section>
    )
}

export default ProductDetailComponent