
export type SiteDataMenuType = {
    name: string;
    href: string;
    submenu: SiteDataMenuType[];
};

export type BannerTypes = {
    banner1: number;
    banner2: number;
}
export type CategoryShow = {
    trending: number;
    hotselling: number;
    fetured: number;
}
export type RazorPay={
  id:number;
}

export type SiteDataType = {
    sitename: string;
    headerlogourl: string;
    topmenu: SiteDataMenuType[];
    config: BannerTypes;
    category: CategoryShow;
    razorpayid:RazorPay;
}


export const siteData: SiteDataType =
{
    sitename: "New Deva Agencies",
    headerlogourl: "https://devaimages.s3.ap-south-1.amazonaws.com/site/devalogoimage.png",
    topmenu: [
        {
            name: "Home",
            href: "#",
            submenu: []
        },
        {
            name: "Screws",
            href: "#",
            submenu: [
                {
                    name: "Locking Screw",
                    href: "#",
                    submenu: [
                        {
                            name: "3.5",
                            href: "#",
                            submenu: []
                        },
                        {
                            name: "5.0",
                            href: "#",
                            submenu: []
                        },
                        {
                            name: "2.7",
                            href: "#",
                            submenu: []
                        },
                    ]
                },
                {
                    name: "Cortical screw ",
                    href: "#",
                    submenu: [
                        {
                            name: "3.5",
                            href: "#",
                            submenu: []
                        },
                        {
                            name: "4.5",
                            href: "#",
                            submenu: []
                        },
                        {
                            name: "2.7",
                            href: "#",
                            submenu: []
                        },
                    ]
                },
                {
                    name: "7mm Cancellous Screw",
                    href: "#",
                    submenu: [
                        {
                            name: "16",
                            href: "#",
                            submenu: []
                        },
                        {
                            name: "32",
                            href: "#",
                            submenu: []
                        },
                    ]
                },

                {
                    name: "4mm Cancellous Screw",
                    href: "#",
                    submenu: []
                },
                {
                    name: "5.0 Locking Head Cancellous Screw",
                    href: "#",
                    submenu: [
                        {
                            name: "16",
                            href: "#",
                            submenu: []
                        },
                        {
                            name: "32",
                            href: "#",
                            submenu: []
                        },
                    ]
                },
                {
                    name: "4.0 Locking Head Cancellous Screw",
                    href: "#",
                    submenu: [
                        {
                            name: "16",
                            href: "#",
                            submenu: []
                        },
                        {
                            name: "32",
                            href: "#",
                            submenu: []
                        },
                    ]
                },
                {
                    name: "6.5 Cancellous Screw",
                    href: "#",
                    submenu: [
                        {
                            name: "16",
                            href: "#",
                            submenu: []
                        },
                        {
                            name: "32",
                            href: "#",
                            submenu: []
                        },
                        {
                            name: "full",
                            href: "#",
                            submenu: []
                        },
                    ]
                },
                {
                    name: "Herbert",
                    href: "#",
                    submenu: []
                },

            ]
        },

        {
            name: "Plates",
            href: "#",
            submenu: [
                {
                    name: "Locking Plates",
                    href: "#",
                    submenu: []
                },
                {
                    name: "Variable Locking Plates",
                    href: "#",
                    submenu: []
                },
                {
                    name: "DCP Plates",
                    href: "#",
                    submenu: []
                },
                {
                    name: "DHS Plates",
                    href: "#",
                    submenu: []
                },
            ]
        },
        {
            name: "Nailing",
            href: "#",
            submenu: [
                {
                    name: "UHN",
                    href: "#",
                    submenu: []
                },
                {
                    name: "Tibia",
                    href: "#",
                    submenu: []
                },
                {
                    name: "Femur",
                    href: "#",
                    submenu: []
                },
                {
                    name: "PFN",
                    href: "#",
                    submenu: []
                },
                {
                    name: "TFN",
                    href: "#",
                    submenu: []
                },
            ]
        },
        {
            name: "Hip Prosthesis",
            href: "#",
            submenu: [
                {
                    name: "Bipolar",
                    href: "#",
                    submenu: []
                },
                {
                    name: "Modular",
                    href: "#",
                    submenu: []
                },
                {
                    name: "AMP",
                    href: "#",
                    submenu: []
                },
            ]
        },
        {
            name: "Fixator",
            href: "#",
            submenu: [
                {
                    name: "Tubular Rod",
                    href: "#",
                    submenu: []
                },
                {
                    name: "Tube to Tube Clamp",
                    href: "#",
                    submenu: []
                },
                {
                    name: "Universal Clamp",
                    href: "#",
                    submenu: []
                },
                {
                    name: "Cancellous Pin",
                    href: "#",
                    submenu: []
                },
                {
                    name: "Cortical Pin",
                    href: "#",
                    submenu: []
                },
                {
                    name: "Tranwarse Clamp",
                    href: "#",
                    submenu: []
                },
            ]
        },
        // {
        //     name: "Contact",
        //     href: "#",
        //     submenu: []
        // },
    ],
    config: {
        banner1: 8,
        banner2: 9
    },
    category: {
        trending: 66,
        hotselling: 68,
        fetured: 69
    },
    razorpayid:{
      id:5471
    }
}

