import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectedProductSlice, updateProduct } from "redux/slices/productSlice";
import { ProductType } from "./ProductType";



type inputProps = {
    data:ProductType;
    imagewidth:number;
    imageheight:number;
}

const ProductCard: React.FC<inputProps> = ({
        data,imagewidth,imageheight
    })=>{

    const navigate = useNavigate();
    //const selectedProduct = useAppSelector(selectedProductSlice)
    const dispatch = useAppDispatch()

    const navigateToProduct = ()=>{
        dispatch(updateProduct({product:data}))
        navigate("/"+data?.url)
    }

    const imageobj = data?.images?.find(function(item:any){
        console.log(item)
        if(item.width===imagewidth && item.height===imageheight){
            return true
        }
        return false
    })

    return (
        <div className="col-lg-3 col-md-4 col-sm-6"
        onClick={() => {
            // 6.4 Button-click: No deal and user clicks on get deals
            console.log("Clicked")
            navigateToProduct()
            /*trackUserAction(
              ActionType.button,
              EventName.deal.getDeal,
              getProductNoDealMoreCore(params.company!)
            );*/
          }}
        >
            <div className="product__item">
                <div className="product__item__pic set-bg" 
                    style={{backgroundImage: `url(`+imageobj?.fullurl+`)`}}
                >
                    <div className="label new">New</div>
                    <ul className="product__hover">
                        <li><a href="img/product/product-1.jpg" className="image-popup"><span className="arrow_expand"></span></a></li>
                        {/* <li><a href="#"><span className="icon_heart_alt"></span></a></li> */}
                        <li><a href="#"><span className="icon_bag_alt"></span></a></li>
                    </ul>
                </div>
                <div className="product__item__text">
                    <h6><a href={data?.url}>{data.name}</a></h6>
                    <div className="rating">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                    </div>
                    <div className="product__price">{data.sp}</div>
                </div>
            </div>
        </div>
    );
}

export default ProductCard;