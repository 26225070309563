import { useAppSelector } from "redux/hooks";
import { accessToken } from "redux/slices/userSlice";
import { Constants } from "utils/Constants";
import { getLocalStorageData, getLocalStorageString } from "utils/LocalStorage";

export const addProductToCart = async (payload:any)=>{
    const url = process.env.REACT_APP_API_ENDPOINT + "/api/cart/product/add";
    const res = await nodeGetMethod(url,payload);
    return res;
}

export const firebaseLogin = async (payload:any)=>{
    const url = process.env.REACT_APP_API_ENDPOINT + "/api/firebasesignup";
    const res = await nodeGetMethod(url,payload);
    return res;
}

export const fetchAddressess = async(payload:any)=>{
    const url = process.env.REACT_APP_API_ENDPOINT + "/api/address/user/fetch";
    const res = await nodeGetMethod(url,payload);
    return res;
}

export const submitAddress = async(payload:any)=>{
    const url = process.env.REACT_APP_API_ENDPOINT + "/api/address/createupdate";
    const res = await nodeGetMethod(url,payload);
    return res;
}

export const updateProfile = async (payload:any)=>{
    const url = process.env.REACT_APP_API_ENDPOINT + "/api/profile/update";
    const res = await nodeGetMethod(url,payload);
    return res;
}

export const fetchUserOrderTransactions = async (payload:any)=>{
    const url = process.env.REACT_APP_API_ENDPOINT + "/api/order/transaction/fetch/all";
    const res = await nodeGetMethod(url,payload);
    return res;
}

export const fetchOrderTransaction = async (payload:any)=>{
    const url = process.env.REACT_APP_API_ENDPOINT + "/api/order/transaction/fetch";
    const res = await nodeGetMethod(url,payload);
    return res;
}

export const updateCartProduct = async (payload:any)=>{
    const url = process.env.REACT_APP_API_ENDPOINT + "/api/cart/product/update";
    const res = await nodeGetMethod(url,payload);
    return res;
}

export const postPaymentStatus = async (payload:any)=>{
    const url = process.env.REACT_APP_API_ENDPOINT + "/api/payment/update";
    const res = await nodeGetMethod(url,payload);
    return res;
}

export const generateOrder = async (payload:any)=>{
    const url = process.env.REACT_APP_API_ENDPOINT + "/api/order/generate";
    const res = await nodeGetMethod(url,payload);
    return res;
}

export const fetchBanners = async () => {
    //const url = process.env.REACT_APP_NODE_API_ENDPOINT + "/banners?";
    const url = process.env.REACT_APP_API_ENDPOINT + "/api/banners/forcompany/fetch";
    const res = await nodeGetMethod(url);
    return res;
};

export const fetchProduct = async (payload:any)=>{
    console.log("Payload for the api")
    console.log(payload)
    const url = process.env.REACT_APP_API_ENDPOINT + "/api/product/detail";
    const res = await nodeGetMethod(url,payload);
    return res;
}

export const fetchRelatedProduct = async (payload:any)=>{
    const url = process.env.REACT_APP_API_ENDPOINT + "/api/product/related/fetch";
    const res = await nodeGetMethod(url,payload);
    return res;
}

export const fetchHomePageProducts = async () => {
    //const url = process.env.REACT_APP_NODE_API_ENDPOINT + "/banners?";
    const url = process.env.REACT_APP_API_ENDPOINT + "/api/products/homepage/fetch";
    const res = await nodeGetMethod(url);
    return res;
};

export const fetchCategoryProducts = async (payload:any) => {
    //const url = process.env.REACT_APP_NODE_API_ENDPOINT + "/banners?";
    const url = process.env.REACT_APP_API_ENDPOINT + "/api/product/category/fetch";
    const res = await nodeGetMethod(url,payload);
    return res;
};
export const searchCategoryProducts = async (payload:any) => {
    //const url = process.env.REACT_APP_NODE_API_ENDPOINT + "/banners?";
    const url = process.env.REACT_APP_API_ENDPOINT + "/api/product/category/search";
    const res = await nodeGetMethod(url,payload);
    return res;
};
export const nodeGetMethod = async (url = "",data={}) => {
    try {
        const localAccessToken = getLocalStorageString(Constants.localStorage.accessToken);
        console.log("Fetching the token")
        console.log(localAccessToken);
        return fetch(url,{
            
            body: JSON.stringify(data),
            method: "POST",
            
            headers: {
                authorization: `Bearer ${localAccessToken}`,
                "Content-Type": "application/json",
            },
        }).then((res) => {
        if (res.status === 200) {
            return res.json();
        } else {
            //logException(res);
            return { error: "something went wrong" };
        }
        });
    } catch (error) {
        //logException(error);
        return error;
    }
};