import { useState,useEffect } from 'react';
import { productMenuList } from 'components/homepage/MenuList'
import MenuLink from 'components/homepage/menu/MenuLink';
import ProductCard from 'components/products/ProductCard';
import { ProductType } from 'components/products/ProductType';
import { useAppSelector } from 'redux/hooks';
import { products } from 'redux/slices/homepageProductsSlice';



const ProductSection : React.FC = () => {

    const [activeMenu, setActiveMenu] = useState(productMenuList[0]);

    const handleMenuOpen = (data:any) => {
        setActiveMenu(data)
    };

    const [productMenu, setProductMenu] = useState<any[]>(
        productMenuList.map((el) => (
            <MenuLink
                key={el.menuitemid}
                data={el}
                onclick={handleMenuOpen}
                activeitem={activeMenu}
            />
            )
        )
    );
    const homepageProducts = useAppSelector(products);
    
    

    const [displayedProducts, setDisplayedProducts] = useState<any[]>(
        homepageProducts
        //.filter(block=>block.category_id===activeMenu.menuitemid)
            .map((el)=>(
                <ProductCard
                    key={el.id}
                    data={el}
                    imagewidth={270}
                    imageheight={360}
                />
        ))
    );

    useEffect(() => {
        setDisplayedProducts(
            homepageProducts
            //.filter(block=>block.category_id===activeMenu.menuitemid)
            .map((el)=>(
                <ProductCard
                    key={el.id}
                    data={el}
                    imagewidth={270}
                    imageheight={360}
                />
            ))
        );
    }, [homepageProducts,activeMenu]);

    useEffect(() => {
        setProductMenu(
            productMenuList.map((el) => (
                <MenuLink
                key={el.menuitemid}
                data={el}
                onclick={handleMenuOpen}
                activeitem={activeMenu}
            />
          ))
        );
    }, [activeMenu]);
    


    return (
        <section className="product spad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-4">
                        <div className="section-title">
                            <h4>New product</h4>
                        </div>
                    </div>
                    {/* <div className="col-lg-8 col-md-8">
                        <ul className="filter__controls">
                            {productMenu}
                        </ul>
                    </div> */}
                </div>
                <div className="row property__gallery">
                    {displayedProducts}
                </div> 
            </div>
        </section>
    );
}

export default ProductSection;