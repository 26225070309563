import { OrderItemType, OrderType } from "components/products/ProductType";
import moment from "moment";
import { useEffect, useState } from "react";


type OrderProps={
    order:OrderType
}

type OrderItemProps = {
    item:OrderItemType
}

const OrderSummary:React.FC<OrderProps> = ({order}) => {

    const [itemsArray,setItemArray] = useState<any[]>([])

    useEffect(()=>{
        let rows:any[] = [];
        order.items?.forEach((el,index)=>{
            rows.push(
                <OrderItem
                    key={index}
                    item={el}
                />
            )

        })
        setItemArray(rows);
    },[order])

    const OrderItem:React.FC<OrderItemProps> = ({item})=>{

        return (
            <>
            <div className="flex-row align-item-center">
                <div className="order-label">Item</div>
                <div className="order-text">{ item.product.name}</div>
                <div className="order-label" style={{marginLeft:20}} >{ item.quantity }</div>
            </div>
            
            
            </>
        )
    }

    return (
        <div className="pdpt-bg">
            {order.status==1 && 
                <div className="pdpt-title">
                    <h6>{order.deliverydate}</h6>
                </div> 
            }

            <div className="order-body10">
                <ul className="order-dtsll">
                    <li>
                        <div className="order-dt-img">
                            
                        </div>
                    </li>
                    <li>
                        <div className="order-dt47">
                            <div className="flex-row align-item-center">
                                <div className="order-label">Order Id: </div><span className="order-text"># {order.id}</span>
                            </div>
                            <div className="flex-row align-item-center">
                                <div className="order-label">Ordered Date:</div> <span className="order-text">{moment(order.created_at).format('DD-MM-YYYY') }</span>
                            </div>
                            <div className="flex-column align-item-center">
                                {itemsArray}    
                            </div>
                               
                            {order.status==3 && 
                                <div className="order-label">Delivered : {order.deliverydate}</div>
                            }
                            {order.status==0 && 
                                <div className="order-label">Order Initiated </div>
                            }
                            {order.status==1 && 
                                <div className="order-label">Order Confirmed </div>
                            }
                            
                        </div>
                    </li>
                </ul>
                
                
                
            </div>
        </div>
    )
}

export default OrderSummary;